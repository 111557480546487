import { Preferences } from '@capacitor/preferences';
import { cookieDomain, getAmplifyConfig } from '../amplifyUtils';
import { getCurrentUser as amplifyGetCurrentUser } from 'aws-amplify/auth';

// This function removes Cognito cookies with a username that does not match the logged in user
export const removeBadCognitoCookies = (username: string): void => {
  const cookies = document.cookie.split(';');
  const userPoolClientId = getAmplifyConfig().Auth.Cognito.userPoolClientId;
  const goodCookiePrefix = `CognitoIdentityServiceProvider.${userPoolClientId}.${username}`;

  cookies.forEach(cookie => {
    const k = cookie.split('=')[0];
    const kTrimmed = k.trim();
    if (kTrimmed.startsWith('CognitoIdentityServiceProvider')) {
      if (!kTrimmed.startsWith(goodCookiePrefix) && !kTrimmed.endsWith("LastAuthUser")) {
        console.log(kTrimmed);
        const newCookie = k + `=; Domain=${cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = newCookie;
      }
    }
  });
};

// This function erases localstorage tokens with a client ID that does not match the current one
export const removeBadLocalStorageTokens = (): void => {
  const userPoolClientId = getAmplifyConfig().Auth.Cognito.userPoolClientId;

  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('CognitoIdentityServiceProvider')) {
      if (!key.startsWith(`CognitoIdentityServiceProvider.${userPoolClientId}`)) {
        localStorage.removeItem(key);
      }
    }
  });
};

export const removeLocalStorageOnboarding = async (): Promise<void> => {
  const keys = await Preferences.keys();
  keys.keys.filter(key => key.startsWith('onboardingStep.') || key.startsWith('completedStep.')).forEach(key => {
    Preferences.remove({ key });
  });
};

export const postLoginCookieCleanup = async (): Promise<void> => {
  try {
    const authUser = await amplifyGetCurrentUser();
    const username = authUser.username;
    if (username) removeBadCognitoCookies(username);
  } catch (e) {
    // Do nothing if there's an issue; we don't want to interrupt auth
  }
};