import { Suspense } from 'react';
import { createBrowserRouter, Outlet, useMatch, useRouteError } from 'react-router-dom';
import { lazyWithPreload as lazy } from 'react-lazy-with-preload';
import { Box, Container } from '@mui/material';

import GlobalModal from './components/GlobalModal';
import CabNavBar from '@CabComponents/CabNavBar';
import { Private, Public, AppUrlListener } from './utils/routing';
import { EXEC_SUBPAGE, PAGE_URL } from './constants';
import CabSpinner from '@CabComponents/CabSpinner';
import { useMountEffect } from './utils/hooks';

// These are the most likely entrypoints of the app
const Dashboard =  lazy(() => import('./pages/Dashboard'));
const PollVotingParticipant =  lazy(() => import('./pages/PollVotingParticipant'));
const BookMeeting =  lazy(() => import('./pages/BookMeeting'));

// NOTE: Any new pages added should be preloaded using the useEffect below
// const OnboardingOverlay =  lazy(() => import('./pages/OnboardingOverlay'));
const Airlines =  lazy(() => import('./pages/Airlines'));
const Personal =  lazy(() => import('./pages/Personal'));
const Login =  lazy(() => import('./pages/Login'));
const ResetPassword =  lazy(() => import('./pages/ResetPassword'));
const SignUp =  lazy(() => import('./pages/SignUp'));
const Test =  lazy(() => import('./pages/Test'));
const Hotels =  lazy(() => import('./pages/Hotels'));
const ProfileCategory =  lazy(() => import('./pages/ProfileCategory'));
const UpdateApp =  lazy(() => import('./pages/UpdateApp'));
const MeetingLog =  lazy(() => import('./pages/MeetingLog'));
const Settings =  lazy(() => import('./pages/Settings'));
const IntegrationSettings =  lazy(() => import('./pages/IntegrationSettings'));
const PageNotFound =  lazy(() => import('./pages/PageNotFound'));
const MaintenanceMode =  lazy(() => import('./pages/Maintenance'));
const SSOSearch =  lazy(() => import('./pages/SSOSearch'));
const PollResultsList =  lazy(() => import('./pages/PollResultsList'));
const PollResults =  lazy(() => import('./pages/PollResults'));
const ReusableMeetings =  lazy(() => import('./pages/ReusableMeetings'));
const AnalyticsReconciliation =  lazy(() => import('./pages/AnalyticsReconciliation'));
const AnalyticsInsights =  lazy(() => import('./pages/AnalyticsInsights'));
const ManageCalendars =  lazy(() => import('./pages/ManageCalendars'));
const TermsOfService =  lazy(() => import('./pages/TermsOfService'));
const ChangeEmail =  lazy(() => import('./pages/ChangeEmail'));
const ExecutiveProfile =  lazy(() => import('./pages/ExecutiveProfile'));
const Schedule =  lazy(() => import('./pages/Schedule'));
const CRMRelationshipsContainer = lazy(() => import('./pages/CRMRelationships'));
const CRMContactsContainer = lazy(() => import('./pages/CRMContacts'));
const CRMCompaniesContainer = lazy(() => import('./pages/CRMCompanies'));
const CRMContactContainer = lazy(() => import('./pages/CRMContact'));
const CRMCompanyContainer = lazy(() => import('./pages/CRMCompany'));
const CRMSearchContainer = lazy(() => import('./pages/CRMSearch'));
// NOTE: Any new pages added should be preloaded using the useEffect below


const Root = () => {
  const bookMeetingMatch = useMatch(`${PAGE_URL.BOOK_MEETING}/:externalId`);
  const pollVotingMatch = useMatch(`${PAGE_URL.GROUP_SCHEDULING_PARTICIPANT}/:meetingToken`);

  useMountEffect(() => {
    if (bookMeetingMatch) {
      BookMeeting.preload();
    } else if (pollVotingMatch) {
      PollVotingParticipant.preload();
    } else {
      Dashboard.preload();
      Schedule.preload();
      Login.preload();
      // OnboardingOverlay.preload();
      Airlines.preload();
      Personal.preload();
      SignUp.preload();
      Hotels.preload();
      ProfileCategory.preload();
      MeetingLog.preload();
      SSOSearch.preload();
      PollResultsList.preload();
      PollResults.preload();
      ReusableMeetings.preload();
      AnalyticsReconciliation.preload();
      AnalyticsInsights.preload();
      ExecutiveProfile.preload();
      CRMRelationshipsContainer.preload();
      CRMContactsContainer.preload();
      CRMCompaniesContainer.preload();
      CRMContactContainer.preload();
      CRMCompanyContainer.preload();
      CRMSearchContainer.preload();

      // These are infrequently visited / low priority pages, so no sense preloading
      // ManageCalendars.preload();
      // PageNotFound.preload();
      // TermsOfService.preload();
      // ChangeEmail.preload();
      // MaintenanceMode.preload();
      // UpdateApp.preload();
      // Test.preload();
      // ResetPassword.preload();
      // Settings.preload();
      // IntegrationSettings.preload();
    }
  });

  return <>
    <GlobalModal />
    <AppUrlListener />
    {/* <OnboardingOverlay /> */}
    <Box display="flex" height="100%">
      {(!bookMeetingMatch && !pollVotingMatch) && (
        <CabNavBar sx={{ flexShrink: 0 }} />
      )}
      <Container id="main" maxWidth={false} disableGutters sx={{ flexGrow: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
          <Suspense fallback={<CabSpinner scale={10} sx={{marginTop: 24}}/>}>
            <Outlet />
          </Suspense>
        </Box>
      </Container>
    </Box>
  </>;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    ErrorBoundary: () => {
      const error = useRouteError();
      throw error;
    },
    children: [
      {
        path:"/",
        element: <Private />,
        children: [
          {
            /* exact */
            path: PAGE_URL.TERMS_OF_SERVICE,
            element: <TermsOfService />
          },
          {
            /* exact */
            path: PAGE_URL.CHANGE_EMAIL,
            element: <ChangeEmail />
          },
          { /* exact */
            path: `${PAGE_URL.EXECUTIVE}/:leaderId/${EXEC_SUBPAGE.AIRLINES}`,
            element:<Airlines /> 
          },
          { /* exact */
            path: `${PAGE_URL.EXECUTIVE}/:leaderId/${EXEC_SUBPAGE.PERSONAL}`,
            element: <Personal />
          },
          { /* exact */
            path: `${PAGE_URL.EXECUTIVE}/:leaderId/${EXEC_SUBPAGE.HOTELS}`,
            element: <Hotels />
          },
          { /* exact */
            path: `${PAGE_URL.EXECUTIVE}/:leaderId/${EXEC_SUBPAGE.PROFILE_CATEGORIES}/:categoryId`,
            element: <ProfileCategory />
          },
          {
            path: `${PAGE_URL.EXECUTIVE}/:leaderId`,
            element: <ExecutiveProfile />
          },
          {
            path: PAGE_URL.DASHBOARD,
            element: <Dashboard />
          },
          {
            path: `${PAGE_URL.SCHEDULE}/oauth/:provider`,
            element: <Schedule />
          },
          {
            path: `${PAGE_URL.SCHEDULE}/:meetingId?`,
            element: <Schedule />
          },
          {
            path: PAGE_URL.MEETINGS,
            element: <MeetingLog />
          },
          {
            path: PAGE_URL.POLL_RESULTS,
            element: <PollResultsList />
          },
          {
            path: `${PAGE_URL.POLL_RESULTS}/:pollIdStr/`,
            element: <PollResults />
          },
          {
            path: PAGE_URL.REUSABLE_MEETINGS,
            element: <ReusableMeetings />
          },
          {
            path: `${PAGE_URL.SETTINGS}/:subPage?`,
            element: <Settings />
          },
          {
            path: PAGE_URL.INTEGRATION_SETTINGS,
            element: <IntegrationSettings />
          },
          {
            path: PAGE_URL.RECONCILIATION,
            element: <AnalyticsReconciliation />
          },
          {
            path: PAGE_URL.INSIGHTS,
            element: <AnalyticsInsights />
          },
          {
            path: PAGE_URL.MANAGE_CALENDARS,
            element: <ManageCalendars />
          },
          {
            path: `${PAGE_URL.INTEGRATION_SETTINGS}/oauth/:provider`,
            element: <IntegrationSettings />
          },
          {
            path: PAGE_URL.CRM_RELATIONSHIPS,
            element: <CRMRelationshipsContainer />,
          },
          {
            path: PAGE_URL.CRM_PEOPLE,
            element: <CRMContactsContainer />,
          },
          {
            path: PAGE_URL.CRM_COMPANIES,
            element: <CRMCompaniesContainer />,
          },
          {
            path: `${PAGE_URL.CRM_PEOPLE}/:contactId`,
            element: <CRMContactContainer />,
          },
          {
            path: `${PAGE_URL.CRM_COMPANIES}/:orgId`,
            element: <CRMCompanyContainer />,
          },
          {
            path: `${PAGE_URL.CRM_SEARCH}`,
            element: <CRMSearchContainer />,
          },
        ]
      },
      {
        path:"/",
        element: <Public />,
        children: [
          {
            path: "*",
            element: <PageNotFound />
          },
          {
            path: PAGE_URL.TEST,
            element: <Test />
          },
          {
            /* exact */ 
            path: `${PAGE_URL.TEST}/oauth/:provider`,
            element: <Test />
          },
          {
            /* exact */ 
            path: `${PAGE_URL.TEST}/:provider?`,
            element: <Test />
          },
          {
            path: PAGE_URL.SSO_SEARCH,
            element: <SSOSearch />
          },
          {
            path: PAGE_URL.SIGNUP + '/oauth/:provider',
            element: <SignUp />
          },
          {
            path: PAGE_URL.SIGNUP + '/:orgCode',
            element: <SignUp />
          },
          {
            path: PAGE_URL.SIGNUP,
            element: <SignUp />
          },
          {
            path: PAGE_URL.RESET_PASS,
            element: <ResetPassword />
          },
          {
            path: PAGE_URL.LOGIN + '/:orgCode',
            element: <Login />
          },
          {
            path: PAGE_URL.LOGIN,
            element: <Login />
          },
          {
            /* exact */
            path: PAGE_URL.UPDATE,
            element: <UpdateApp />
          },
          {
            /* exact */
            path: PAGE_URL.MAINTENANCE,
            element: <MaintenanceMode />
          },
          {
            /* exact */
            path: `${PAGE_URL.BOOK_MEETING}/reschedule/:meetingId/:meetingToken`,
            element: <BookMeeting />
          },
          {
            /* exact */
            path: `${PAGE_URL.BOOK_MEETING}/cancel/:meetingId/:meetingToken`,
            element: <BookMeeting />
          },
          {
            /* exact */
            path: `${PAGE_URL.BOOK_MEETING}/:meetingId`,
            element: <BookMeeting />
          },
          {
            /* exact */
            path: `${PAGE_URL.GROUP_SCHEDULING_PARTICIPANT}/:meetingToken`,
            element: <PollVotingParticipant />
          },
        ]
      },
    ]
  }
]);

// this allows clearing navigation state without triggering a re-render
export const clearNavigationState = () => window.history.replaceState({}, '');
