import { ActionType } from '../actionTypes';
import { OrganizationState } from './types';
import { RootAction } from '..';

export const initialState: OrganizationState = {
  id: -1,
  name: '',
  logo: '',
  userAutocompleteOptions: [],
  subscriptionDetails: null,
  num_eas: null,
  owner: {
    id: -1,
    first_name: "",
    last_name: "",
    email: ""
  },
  is_on_contract: false, 
  trialing: false,
  active: false,
  unpaid_trial_end_date: null,
  onboarding_call_scheduled: false,
  idle_timeout: null
};

export default function organization(state = initialState, action: RootAction): typeof initialState  {
  switch (action.type) {
    case ActionType.FETCHED_ORG_USER_AUTOCOMPLETE_OPTIONS:
      return {...state, userAutocompleteOptions: action.options};

    case ActionType.CHANGED_SUBSCRIPTION:
      if (state.subscriptionDetails) {
        return {
          ...state, subscriptionDetails: {
            ...state.subscriptionDetails,
            tier: action.subscriptionDetails.tier
          }
        };
      } else {
        return state;
      }
    case ActionType.FETCHED_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        subscriptionDetails: action.subscriptionDetails
      };

    

    case ActionType.SET_ORGANIZATION:
      return {...state, ...action.org};

    case ActionType.LOGOUT_SUCCESSFUL:
      return initialState;
    
    case ActionType.UPDATE_ORGANIZATION:
      return {
        ...state,
        id: action.org.id,
        name: action.org.name || state.name,
        logo: action.org.logo || state.logo,
        num_eas: action.org.num_eas == null ? state.num_eas : action.org.num_eas,
        onboarding_call_scheduled: action.org.onboarding_call_scheduled || state.onboarding_call_scheduled
      };

    case ActionType.UPDATE_ORGANIZATION_LICENSES:
      return {...state, licenses: action.licenses};

    case ActionType.SET_PARTIAL_ORGANIZATION:
      return {...state, ...action.org};

    default:
      return state;
  }
}