import { createTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import colors from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary'];
  }
  interface PaletteColor {
    accent?: string;
  }
  interface SimplePaletteColorOptions {
    accent?: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    accent: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: colors.greenLight,
      main: colors.greenPrimary,
      contrastText: '#fff',
    },
    secondary: {
      light: '#e5f0f6',
      main: '#79a6d5',
      dark: '#c7d1d8',
      contrastText: '#ffffff',
    },
    error: {
      main: '#bb0000',
    },
    accent: {
      light: colors.navyLight,
      main: colors.navyPrimary,
      dark: colors.navyDark,
      contrastText: '#fff',
    }
  },
  typography: {
    fontFamily: 'Greycliff CF',
    caption: {
      fontSize: 10,
      lineHeight: '12px',
      color: 'black',
    },
    body1: {
      fontFamily: 'Greycliff CF',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '22px',
      color: 'black',
    },
    body2: {
      fontFamily: 'Greycliff CF',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '22px',
      color: 'black',
    },
    h4: {
      fontFamily: 'Greycliff CF',
      fontWeight: 500,
      fontSize: 20,
      color: 'black',
    },
    h5: {
      fontFamily: 'Greycliff CF',
      fontWeight: 500,
      fontSize: 18,
      color: 'black',
    },
    h1: {
      fontFamily: 'Greycliff CF',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '31px',
      color: 'black',
    },
    h2: {
      fontFamily: 'Greycliff CF',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '22px',
    },
    subtitle1: {
      fontSize: 12,
      fontFamily: 'Greycliff CF',
      fontWeight: 500,
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: 'Greycliff CF',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '22px',
    },
    button: {
      fontFamily: 'Greycliff CF',
      fontWeight: 500,
      fontSize: 16, 
      lineHeight: '20px',
      textTransform: 'initial',
      boxShadow: 'none',
    },
  },
}, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          ":hover": {
            boxShadow: 'none',
          }
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '22px',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          color: colors.black900,
          fontSize: 16,
          lineHeight: '23px',
          '&.MuiFormLabel-colorPrimary': { color: colors.black900 },
          '&.MuiFormLabel-colorPrimary .Mui-disabled': { color: colors.black400 },
        }
      }
    },
  }
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.white900,
    },
    secondary: {
      main: chroma(colors.black900).brighten(0.6).hex(),
    },
    error: {
      main: '#bb0000',
    },
    accent: {
      main: colors.greenLight,
      light: colors.greenPrimary,
      contrastText: colors.navyDark,
    },
  },
  typography: theme.typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          ":hover": {
            boxShadow: 'none',
          }
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.white900
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          boxShadow: 'none',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '22px',
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '23px',
          color: colors.white900,
          '&.MuiFormLabel-colorPrimary': { color: colors.white900 },
          '&.MuiFormLabel-colorPrimary .Mui-disabled': { color: colors.white400 },
        }
      }
    },
  }
});

export default theme;
