import { ReactElement, useMemo } from "react";
import { 
  Autocomplete, AutocompleteProps, AutocompleteRenderInputParams, MenuItem, Popper, PopperProps, styled 
} from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";
import { CabToggleChip } from "../CabToggleChip";
import { CabTooltip } from "../CabTooltip";
import { CabIcon } from "../CabIcon";
import { CabInput } from "../CabInput";
import { IoChevronDown, IoCloseOutline } from "react-icons/io5";


export interface GenericAutocompleteOption<T, M> { 
  value: T, 
  label: string, 
  color?: string, 
  key?: number, 
  meta?: M
  tooltipTitle?: string
  disabled?: boolean
}

type GenericAutocompleteProps<T, M = {[key: string]: string}> = 
AutocompleteProps<(GenericAutocompleteOption<T, M> & { 
  disabled?: boolean;
  tooltipTitle?: string;
  disabledChip?: boolean
  icon?: React.ReactElement
}), boolean, boolean, boolean>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GenericAutocomplete = <T, M>(props: GenericAutocompleteProps<T, M>) => (
  <Autocomplete {...props} />
);

export interface CabAutocompleteProps<T, M> extends CabComponentProps<GenericAutocompleteProps<T, M>> {
  options: GenericAutocompleteProps<T, M>['options'];
  groupBy?: GenericAutocompleteProps<T, M>['groupBy'];
  renderGroup?: GenericAutocompleteProps<T, M>['renderGroup'];
  getOptionLabel?: GenericAutocompleteProps<T, M>['getOptionLabel'];
  filterOptions?: GenericAutocompleteProps<T, M>['filterOptions'];
  onInputChange?: (value: string) => void
  onChange: (value: T|T[]|null) => void
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  renderTags?: GenericAutocompleteProps<T, M>['renderTags'];
  alignRight?: boolean;
  size?: GenericAutocompleteProps<T, M>['size'];
  disabled?: GenericAutocompleteProps<T, M>['disabled'];
  readOnly?: GenericAutocompleteProps<T, M>['readOnly'];
  label?: string;
  ListboxProps?: GenericAutocompleteProps<T, M>['ListboxProps'];
  value?: T|T[]|null;
  inputValue?: GenericAutocompleteProps<T, M>['inputValue'];
  multiple?: GenericAutocompleteProps<T, M>['multiple'];
  openOnFocus?: GenericAutocompleteProps<T, M>['openOnFocus'];
  onFocus?: GenericAutocompleteProps<T, M>['onFocus']
  onKeyDown?: GenericAutocompleteProps<T, M>['onKeyDown']
  defaultValue?: T|T[];
  placeholder?: string;
  className?: GenericAutocompleteProps<T, M>['className'];
  clearable?: boolean;
  hideArrow?: boolean;
  inputRef?:  React.Ref<HTMLInputElement>;
  noOptionsText?: GenericAutocompleteProps<T, M>['noOptionsText'];
  autoSelect?: GenericAutocompleteProps<T, M>['autoSelect'];
  autoHighlight?: GenericAutocompleteProps<T, M>['autoHighlight'];
  freeSolo?: GenericAutocompleteProps<T, M>['freeSolo'];
  getOptionsDisabled?: GenericAutocompleteProps<T, M>['getOptionDisabled'];
  blurOnSelect?: GenericAutocompleteProps<T, M>['blurOnSelect'];
  narrow?: boolean;
  loading?: boolean;
  helperText?: string;
  id?: string;
}

export const CabAutocomplete = <T, M>({
  onChange, onInputChange, options, value, inputValue, multiple, defaultValue, placeholder, clearable, loading, id,
  label, ListboxProps, getOptionLabel, hideArrow, noOptionsText, narrow, openOnFocus = true, disabled, readOnly, size,
  autoSelect, autoHighlight, freeSolo, sx, className, overrides, filterOptions, onFocus, onKeyDown, inputRef,
  helperText, groupBy, renderGroup, getOptionsDisabled, alignRight, blurOnSelect
}: CabAutocompleteProps<T, M>): ReactElement|null => {
  const mapValue = (val: T |T[] | null | undefined) => val != null
    ? Array.isArray(val)
      ? val.map(v => ({
        value: v,
        label: options.find(o => o.value === v)?.label || '', 
        color: options.find(o => o.value === v)?.color || '',
        disabled: options.find(o => o.value === v)?.disabled,
        disabledChip: options.find(o => o.value === v)?.disabledChip,
        tooltipTitle: options.find(o => o.value === v)?.tooltipTitle,
        icon: options.find(o => o.value === v)?.icon,
      }))
      : {
        value: val,
        label: options.find(o => o.value === val)?.label || '',
      }
    : undefined;
  
  // This is necessary to keep value from changing every render which prevents typing from working correctly
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedValue = useMemo(() => mapValue(value), [JSON.stringify(value)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedDefaultValue = useMemo(() => mapValue(defaultValue), [JSON.stringify(defaultValue)]);

  return <AutocompleteStyled<T, M>
    id={id}
    options={options}
    groupBy={groupBy}
    renderGroup={renderGroup}
    getOptionLabel={getOptionLabel}
    getOptionDisabled={getOptionsDisabled}
    filterOptions={filterOptions}
    onFocus={onFocus}
    onKeyDown={onKeyDown}
    noOptionsText={noOptionsText}
    multiple={multiple}
    disableCloseOnSelect={multiple ? true : false}
    disabled={disabled}
    readOnly={readOnly}
    freeSolo={freeSolo}
    inputValue={inputValue}
    value={memoizedValue}
    ListboxProps={ListboxProps}
    blurOnSelect={blurOnSelect}
    defaultValue={memoizedDefaultValue}
    onChange={(e, v) => {
      if (typeof v === 'string') return;
      if (Array.isArray(v)) {
        const ret: T[] = [];
        v.forEach(arrayVal => {
          if (typeof arrayVal !== 'string') {
            ret.push(arrayVal.value);
          }
        });
        onChange(ret);
      } else {
        onChange(v?.value || null);
      }
    }}
    filterSelectedOptions={multiple}
    onInputChange={onInputChange && ((e, v) => onInputChange(v))}
    isOptionEqualToValue={(option, v) => {
      return option.value === v.value;
    }}
    renderInput={(p) => <CabInput {...p} placeholder={placeholder} inputRef={inputRef} label={label} 
      helperText={helperText} disabled={disabled} />}
    renderTags={(tagValue, props) =>
      tagValue.map((v, index) => {
        const { key, ...p } = props({ index });
        const isDisabled = v.disabled || p.disabled || !!v.disabledChip;

        return v.label && (
          <CabTooltip key={key} title={v.tooltipTitle ? v.tooltipTitle : ''} wrapWithSpan>
            <CabToggleChip
              label={v.label}
              icon={v.icon}
              selected
              chipColor={v.color}
              translateColor
              {...p}
              disabled={isDisabled}
            />
          </CabTooltip>
        );
      })}
    renderOption={(props, option) => {
      const key = option.key ? `${option.key}` : `${option.value}`;    
      return (
        <CabTooltip key={key} title={option.disabled ? option.tooltipTitle : ''} wrapWithSpan>
          <MenuItem {...props} disabled={option.disabled} sx={{fontSize: size === "small" ? 14 : 'inherit'}}>
            {option.label}
          </MenuItem>
        </CabTooltip>
      );
    }}
    popupIcon={hideArrow || disabled || readOnly ? null : <CabIcon size="small" Icon={IoChevronDown} />}
    clearIcon={<CabIcon size="small" alt="Close" Icon={IoCloseOutline} />}
    PopperComponent={narrow ? NarrowStyledPopper : StyledPopper}
    slotProps={alignRight ? {
      popper: { sx: { direction: 'rtl' } },
      paper: { sx: { direction: 'ltr' } }
    } : undefined}
    {...overrides}
    size={size}
    sx={sx}
    className={className}
    disableClearable={!clearable}
    openOnFocus={openOnFocus}
    autoSelect={autoSelect}
    autoHighlight={autoHighlight}
    loading={loading}
    // ListboxProps={{ sx: (theme: Theme) => (theme.palette.mode === 'dark' ? { color: colors.white900 } : {}) }}
  />;
};

const StyledPopper = styled(Popper, { label: "StyledPopper" }
)<CabComponentProps<PopperProps>>(({ theme }) => ({
  '& .MuiAutocomplete-paper': {
    minWidth: 'fit-content',
  },
  '& .MuiAutocomplete-listbox': {
    ...(theme.palette.mode === 'dark' && {
      '& .MuiMenuItem-root': {
        color: colors.white900,
      },
      backgroundColor: colors.white100,
    }),
    '::-webkit-scrollbar': {
      width: "10px",
      height: 8,
      backgroundColor: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.black500,
      borderRadius: 5,
    },
  }
}));

const NarrowStyledPopper = styled(
  StyledPopper, { label: "NarrowStyledPopper" }
)<CabComponentProps<PopperProps>>(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    '::-webkit-scrollbar': {
      width: "6px",
      height: 8,
      backgroundColor: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.black500,
      borderRadius: 3,
    }
  },
}));

const AutocompleteStyled = styled(Autocomplete, {
  label: "AutocompleteStyled" }
)<CabComponentProps<GenericAutocompleteProps<unknown>>>(({ theme, multiple, size }) => ({
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    fontSize: 14,
    alignContent: 'center',
    lineHeight: '25px',
    height: 32,
  },
  ...(size !== 'small' && {
    '& .MuiInputBase-root': {
      padding: 6,
    },
    '& .MuiInputBase-root .MuiAutocomplete-input': {
      padding: "2.5px 4px 2.5px 6px"
    },
  }),
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: colors.black50,
  }
})) as typeof GenericAutocomplete;