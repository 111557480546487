import { ActionType } from '../actionTypes';
import { LeaderList, Leader, LeaderOrder, SharedLeaderGrantCollection, OrganizationLeader } from './types';
import { RootAction } from '..';
import { getLeaderPermission } from '../templates';
import cloneDeep from 'lodash/cloneDeep';
import { uniqBy } from 'lodash-es';

export const initialState: LeaderList = {
  loaded: false,
  orgLeadersLoaded: false,
  leaders: [],
  orgLeaders: [],
  orgSharedLeaderGrants: {},
  orgLeaderCategories: {}
};

export default function leaders(state = initialState, action: RootAction): typeof initialState {
  switch (action.type) {
    case ActionType.FETCHED_LEADERS: {
      const fetchedLeaders = action.leaders;
      return { ...state, loaded: true, leaders: fetchedLeaders };
    }
    case ActionType.SHARED_LEADER: {
      return {
        ...state,
        orgSharedLeaderGrants: { ...state.orgSharedLeaderGrants, [action.leaderId]: action.grants },
      };
    }
    case ActionType.FETCHED_ORG_LEADER_CATEGORIES: {
      const categories = action.categories;
      return { ...state, orgLeaderCategories: categories };
    }
    case ActionType.FETCHED_ORG_SHARED_LEADER_GRANTS: {
      const grants = action.grants;
      return { ...state, orgSharedLeaderGrants: grants };
    }
    case ActionType.FETCHED_ORG_LEADERS: {
      const orgLeaders = action.orgLeaders;
      return { ...state, orgLeadersLoaded: true, orgLeaders: orgLeaders };
    }
    case ActionType.REVOKED_SHARED_LEADER_GRANT: {
      if (state.orgSharedLeaderGrants[action.leaderId]) {
        const postRevokeSharedLeaderGrants = state.orgSharedLeaderGrants[action.leaderId].slice();
        return {
          ...state,
          orgSharedLeaderGrants: {
            ...state.orgSharedLeaderGrants,
            [action.leaderId]: postRevokeSharedLeaderGrants.filter(grant => grant.id !== action.grantId),
          }
        };
      }
      return state;
    }
    case ActionType.CREATED_LEADER: {
      return { ...state, leaders: [...state.leaders, action.leader] };
    }
    case ActionType.CREATED_ADMIN_LEADER: {
      return { ...state, orgLeaders: [...state.orgLeaders, action.orgLeader] };
    }
    case ActionType.DELETED_LEADER: {
      const newLeaders = state.leaders.filter((leader): boolean => 
        leader.id !== action.leaderId
      );
      const newOrgLeaders = state.orgLeaders.filter((l): boolean =>
        l.id !== action.leaderId
      );
      if (newLeaders.length === 0) {
        if (newOrgLeaders.length > 0) {
          return { ...state, leaders: [], orgLeaders: newOrgLeaders};
        }
        return { ...state, leaders: [], orgLeaders: [] };
      } else {
        return { ...state, leaders: newLeaders, orgLeaders: newOrgLeaders };
      }
    }
    case ActionType.UPDATED_LEADER_CALENDARS: {
      let updatedCalendarsLeaders = state.leaders;
      action.associations.forEach(association => {
        const currentLeader = updatedCalendarsLeaders.find(l => l.id === association.leader_id);
        if (currentLeader) {
          let leaderCalendars = currentLeader.leader_calendars;
          if (leaderCalendars[association.calId]) {
            leaderCalendars[association.calId] = {
              ...leaderCalendars[association.calId],
              prevent_conflict: association.prevent_conflict,
              allow_calendar_analytics: association.allow_calendar_analytics
            };
          } else {
            leaderCalendars = {
              ...leaderCalendars,
              [association.calId]: {
                prevent_conflict: association.prevent_conflict,
                allow_calendar_analytics: association.allow_calendar_analytics,
                calendar: association.calId,
                leader: association.leader_id
              }
            };
          }
          updatedCalendarsLeaders = uniqBy(
            [{ ...currentLeader, leader_calendars: leaderCalendars }, ...updatedCalendarsLeaders], 
            l => l.id
          );
        }
      });
      return { ...state, leaders: updatedCalendarsLeaders.sort((a, b) => a.order - b.order) };
    }

    case ActionType.UPDATED_LEADER: {
      const tempLeaders = state.leaders.map((leader): Leader => {
        if (leader.id === action.leader.id) {
          return action.leader;
        }
        return leader;
      });
      return { ...state, leaders: tempLeaders };
    }
    case ActionType.UPDATED_ADMIN_LEADER: {
      const tempOrgLeaders = state.orgLeaders.map((leader): OrganizationLeader => {
        if (leader.id === action.orgLeader.id) {
          return action.orgLeader;
        }
        return leader;
      });
      return { ...state, orgLeaders: tempOrgLeaders };
    }
    case ActionType.REORDER_LEADERS: {
      const currentLeaders = state.leaders;
      const leaderOrderArray: LeaderOrder[] = Object.entries(action.leaderOrders).map(leader => ({
        leaderId: Number(leader[0]),
        order: leader[1]
      }));
      const sortedIds = leaderOrderArray.sort((a, b) => a.order - b.order).map(item => item.leaderId);
      const leadersOrdered = currentLeaders.sort((a, b) => sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id));

      return { ...state, leaders: [...leadersOrdered] };
    }
    case ActionType.CREATED_PROFILE_CATEGORY: {
      const createCategoryLeaderIdx = state.leaders.findIndex(leader => leader.id === action.profileCategory.leader);
      const beforeCreateCategoryLeaders = cloneDeep(state.leaders);
      beforeCreateCategoryLeaders[createCategoryLeaderIdx]
        .permissions.categories[action.profileCategory.id] = getLeaderPermission(false);

      return { ...state, leaders: beforeCreateCategoryLeaders };
    }
    case ActionType.DELETED_PROFILE_CATEGORY: {
      const deleteCategoryLeaderIdx = state.leaders.findIndex(leader => leader.id === action.profileCategory.leader);
      const beforeDeleteCategoryLeaders = cloneDeep(state.leaders);
      delete beforeDeleteCategoryLeaders[deleteCategoryLeaderIdx].permissions.categories[action.profileCategory.id];
      return { ...state, leaders: beforeDeleteCategoryLeaders };
    }
    case ActionType.LOGOUT_SUCCESSFUL: 
      return initialState;
    case ActionType.FETCHED_SHARED_LEADER_GRANTS: {
      const postFetchSharedLeaderGrants: SharedLeaderGrantCollection = {};
      action.grants.forEach(grant => {
        if (postFetchSharedLeaderGrants[grant.leader]) {
          postFetchSharedLeaderGrants[grant.leader] = [...postFetchSharedLeaderGrants[grant.leader], grant];
        } else {
          postFetchSharedLeaderGrants[grant.leader] = [grant];
        }
      });
      return { ...state, orgSharedLeaderGrants: {...state.orgSharedLeaderGrants, ...postFetchSharedLeaderGrants} };
    }
    default:
      return state;
  }
}


