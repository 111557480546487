import { ReactElement } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, BoxProps, 
  List, styled, Typography } from '@mui/material';
import { CabComponentProps } from "../../cabStyled";
import { User } from "../../../../store";
import { isLargeFormatDevice, isMobile } from "../../../../utils/screenSizeUtils";
import { colors } from "../../../../colors";
import { PAGE_URL } from "../../../../constants";
import { CabNavItem } from "../CabNavItem";
import { CabIcon } from "@CabComponents/CabIcon";
import { 
  IoCalendarOutline, IoExtensionPuzzleOutline, IoHelpCircleOutline, IoLogOutOutline, IoPeopleOutline, IoSettingsOutline 
} from "react-icons/io5";

export interface CabNavSettingsProps extends CabComponentProps<BoxProps>  {
  sx?: BoxProps['sx'];
  expanded: boolean;
  user: User | null | undefined;
  isBarExpanded: boolean;
  onToggle: (expanded: boolean) => void;
  onChangePage: (pageUrl: string, external?: boolean) => void;
  onLogout: () => void;
  versionNumber: string;
}

export const CabNavSettings = ({
  overrides, expanded, sx, user, isBarExpanded, onToggle, onChangePage, onLogout, versionNumber,
}: CabNavSettingsProps): ReactElement => {  
  const props: Omit<BoxProps, 'children'> = {
    sx
  };

  const handleToggleAccordion = (isExpanded: boolean) => {
    onToggle(isExpanded);
  };

  const handleChangePage = (pageUrl: string, external?: boolean): void => {
    handleToggleAccordion(false);
    onChangePage(pageUrl, external);
  };

  const handleLogout = (): void => {
    handleToggleAccordion(false);
    onLogout();
  };
  
  return (
    <BoxStyled {...props} {...overrides} sx={{...sx, borderTop: `1px solid ${colors.black100}`}}>
      {isLargeFormatDevice() && (
        <ListStyled>
          <MenuButtonStyled
            key={"Support"}
            open={isBarExpanded}
            text={"Support"}
            active={false}
            Icon={IoHelpCircleOutline}
            locked={false}
            //onClick={() => handleChangePage(HELP_CENTER_URL, true)}
            elementId="custom_intercom_launcher"
          />
        </ListStyled>
      )}
      <AccordionStyled 
        disableGutters 
        square 
        elevation={0}
        expanded={expanded} 
        onChange={(event, newExpanded) => handleToggleAccordion(newExpanded)}
      >
        <AccordionSummaryStyled
          id='CabNavSettings-my-account' 
          expandIcon={isBarExpanded ? <CabIcon Icon={IoSettingsOutline} alt='Settings'/> : undefined}
          expanded={expanded}
          widthCollapsed={!isBarExpanded}
        >
          <AvatarStyled alt="More Options" src={user?.profile.valid_pic_url}/>
          {isBarExpanded && 
            <SummaryText noWrap> 
              My Account
            </SummaryText>
          }
        </AccordionSummaryStyled>

        <AccordionDetailsStyled>
          <ListStyled>
            <MenuButtonStyled
              key={"Calendar Settings"}
              open={isBarExpanded}
              text={"Calendar Settings"}
              active={false}
              Icon={IoCalendarOutline}
              locked={false}
              onClick={() => handleChangePage(PAGE_URL.MANAGE_CALENDARS)}
            />
            {!isMobile() && 
              <MenuButtonStyled
                key={"Integrations"}
                open={isBarExpanded}
                text={"Integrations"}
                active={false}
                Icon={IoExtensionPuzzleOutline}
                locked={false}
                onClick={() => handleChangePage(PAGE_URL.INTEGRATION_SETTINGS)}
                elementId='CabNavSettings-account-integrations'
              />
            }
            {(user?.features?.SUPPORT_MAP && user.permissions.READ_LEADER_DATA_ACCESS && user.features.COLLABORATION) &&
              <MenuButtonStyled
                key={"Support Map"}
                open={isBarExpanded}
                text={"Support Map"}
                active={false}
                Icon={IoPeopleOutline}
                locked={false}
                onClick={() => handleChangePage(PAGE_URL.SUPPORT_MAP)}
              />
            }
            <MenuButtonStyled
              key={"Settings"}
              open={isBarExpanded}
              text={"Settings"}
              active={false}
              Icon={IoSettingsOutline}
              locked={false}
              onClick={() => handleChangePage(PAGE_URL.SETTINGS)}
            />
            <MenuButtonStyled
              key={"Logout"}
              open={isBarExpanded}
              text={"Logout"}
              active={false}
              Icon={IoLogOutOutline}
              locked={false}
              onClick={() => handleLogout()}
            />
          </ListStyled>

          <VersionNumberText align="right">
            {versionNumber.match(/[0-9][0-9a-zA-Z.\-+]*[0-9]/)}
          </VersionNumberText>
        </AccordionDetailsStyled>
      </AccordionStyled>
    </BoxStyled>
  );
};

const AccordionStyled = styled(Accordion, {label: "AccordionStyled"})(({ theme }) => ({
  backgroundColor: colors.neutral,
  display: 'flex',
  flexDirection: 'column-reverse'
}));

const AccordionSummaryStyled = styled(AccordionSummary, {
  shouldForwardProp: (prop) => prop !== 'expanded' && prop !== 'widthCollapsed', label: "AccordionSummaryStyled"
})<{expanded: boolean; widthCollapsed: boolean}>(({ theme, expanded, widthCollapsed }) => ({
  paddingLeft: widthCollapsed ? 2 : 10,
  paddingRight: 10,
  justifyContent: 'left',
  backgroundColor: expanded ? colors.black200 : 'inherit',
  '&:hover': {
    backgroundColor: colors.black200
  },
  borderTop: `1px solid ${colors.white800}`,
  '& .MuiAccordionSummary-content': {
    marginTop: 10,
    marginBottom: 10,
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    fontSize: 22
  }
}));

const AccordionDetailsStyled = styled(AccordionDetails, {label: "AccordionDetailsStyled"})(({ theme }) => ({
  padding: 0,
}));

const AvatarStyled = styled(Avatar, {label: "AvatarStyled"})(({theme}) => ({
  height: 36,
  width: 36,
  marginRight: 10,
  marginLeft: 8
}));

const SummaryText = styled(Typography, {label: "UserNameText"})(({theme}) => ({
  marginTop: '11px',
  fontSize: 15,
  maxWidth: '130px',
  lineHeight: 1
}));

const VersionNumberText = styled(Typography, {label: "VersionNumberText"})(({theme}) => ({
  borderTop: `1px solid ${colors.white800}`,
  fontSize: 12,
  paddingRight: 10
}));

const ListStyled = styled(List, {label: "ListStyled"})(({theme}) => ({
  padding: 0,
  backgroundColor: colors.neutral,
  borderTop: `1px solid ${colors.white800}`,
}));

const MenuButtonStyled = styled(CabNavItem, {label: "MenuButtonStyled"})(({theme}) => ({
  marginTop: 0,
  marginBottom: 0,
}));

const BoxStyled = styled(Box, {label: "BoxStyled"})(({theme}) => ({
  // This is required to show the settings item on mobile when there is a notch.
  // Applying fixes to the root NavBar itself didn't work; any changes 
  // were overriden by something in the ether.
  bottom: 'env(safe-area-inset-top)',
}));