import { FC, ReactElement, useEffect } from 'react';
import { Outlet, Routes, useLocation, useNavigate, Location as ReactRouterLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, ThunkDispatchType, actions, } from '../store';
import { NEW_TIER_IDS, PAGE_URL } from '../constants';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { withSentryRouting } from '@sentry/react';
import { Browser } from '@capacitor/browser';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import CabSpinner from '@CabComponents/CabSpinner';
import { useIdleTimer } from 'react-idle-timer';

export const SentryRoutes = withSentryRouting(Routes);

const useTermsOfService = () => {
  const location = useLocation();
  const user = useSelector((root: RootState) => root.auth.user);
  const navigate = useNavigate();
  if (user?.tos_acceptance_required && !(location.pathname === PAGE_URL.TERMS_OF_SERVICE)) {
    navigate(PAGE_URL.TERMS_OF_SERVICE);
  }
};

const useUnlicensedUserRouting = () => {
  const location = useLocation();
  const user = useSelector((root: RootState) => root.auth.user);
  const organization = useSelector((root: RootState) => root.organization);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      (!user?.features.UNPAID_LICENSE
      && !organization.active
      && !organization.trialing
      && (organization.unpaid_trial_end_date != null
      && DateTime.fromISO(organization.unpaid_trial_end_date).toMillis() <= DateTime.now().toMillis())
      && (location.pathname !== PAGE_URL.SUBSCRIPTION))
      || (user && !user.active_license.is_active)
      || (user && user.active_license.license_tier === NEW_TIER_IDS['BASIC'])
    ) {
      navigate(PAGE_URL.SUBSCRIPTION);
    }
  }, [navigate, user, user?.features.UNPAID_LICENSE, user?.tos_acceptance_required, organization,
    location.pathname, user?.active_license.is_active, user?.active_license.license_tier]);
};

const useDefaultRedirects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/app") {
      return navigate(PAGE_URL.DASHBOARD);
    }
  }, [location, navigate]);
};

const useRequireLogin = () => {
  const location = useLocation();
  const user = useSelector((root: RootState) => root.auth.user);
  const isLoading = useSelector((root: RootState) => root.auth.isLoading);
  const isAuthenticated = useSelector((root: RootState) => root.auth.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatchType>();
  useEffect(() => {
    // checking for update or maintenance so location state is preserved
    if (!isLoading && !isAuthenticated 
      && window.location.pathname !== PAGE_URL.MAINTENANCE && window.location.pathname !== PAGE_URL.UPDATE) {
      const redirectPathname = location.state ? location.state.referrer : window.location.pathname;
      dispatch(actions.auth.setAuthRedirect(redirectPathname));
      const state = {
        ...location.state,
        referrer: redirectPathname, from: { pathname: location.pathname }
      };
      navigate(PAGE_URL.LOGIN, { state});
    }
  }, [isLoading, isAuthenticated, navigate, location.pathname, user, location.state, dispatch]);
};

const useLoginRedirect = (isPublic = false) => {
  
  const location = useLocation();
  const user = useSelector((root: RootState) => root.auth.user);
  const isLoading = useSelector((root: RootState) => root.auth.isLoading);
  const redirectUrl = useSelector((root: RootState) => root.auth.redirectUrl);
  const isAuthenticated = useSelector((root: RootState) => root.auth.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatchType>();
  useEffect(() => {
    // we can't make any redirect decisions for private links until auth loading has finished
    if (!isPublic && isLoading) return;

    let pathname = location.pathname;
    let replace = false;
    if (!isPublic && !isAuthenticated && !redirectUrl && !pathname.includes(PAGE_URL.LOGIN)) {
      pathname = PAGE_URL.LOGIN;
    }
    
    
    let state: ReactRouterLocation["state"] | undefined;
    if (
      location.state?.from?.pathname
      && !location.state?.from?.pathname.includes(PAGE_URL.LOGIN)
      && !location.state.from.pathname.includes(PAGE_URL.CHANGE_EMAIL)
    ) {
      pathname = location.state.from.pathname;
      // NOTE: This will aggresively clear our state for us so it's only used one time
      state = {};
    } else if (
      redirectUrl && location.pathname.includes(PAGE_URL.LOGIN)
    ) {
      pathname = redirectUrl;
      dispatch(actions.auth.setAuthRedirect(null));
      replace = true;
    } else if (isAuthenticated && user) {
      if (user.profile && !user.profile.app_onboarding_completed
        && !location.pathname.includes(PAGE_URL.BOOK_MEETING)
        && !location.pathname.includes(PAGE_URL.GROUP_SCHEDULING_PARTICIPANT)
      ) {
        pathname = PAGE_URL.SIGNUP;
      } else if (PAGE_URL.LOGIN === location.pathname) {
        pathname = PAGE_URL.DASHBOARD;
      }
    } 
    // only go to the path if it is not the current
    if (pathname !== location.pathname) {
      navigate(pathname, {replace: replace, state: state});
    }
  }, [
    navigate, isAuthenticated, location.state?.from?.pathname, location.pathname,
    location.state, user, isLoading, redirectUrl, dispatch, isPublic
  ]);
};


export const Public = () => {
  useDefaultRedirects();
  useLoginRedirect(true);
  
  return <Outlet />;
};

export const Private = (): ReactElement => {

  const isLoading = useSelector((root: RootState) => root.auth.isLoading);
  const isAuthenticated = useSelector((root: RootState) => root.auth.isAuthenticated);
  const idleTimeout = useSelector((root: RootState) => root.organization.idle_timeout);

  const dispatch = useDispatch<ThunkDispatchType>();

  useIdleTimer({
    onIdle: () => {
      if (isAuthenticated && idleTimeout ) {
        dispatch(actions.auth.logout());
      }
    },
    disabled: !idleTimeout,
    onActive: undefined,
    onAction: undefined,
    // If 0 and disabled an infinite loop occurs
    timeout: idleTimeout || 1,
    throttle: idleTimeout || 1,
  });

  useDefaultRedirects();
  useLoginRedirect();
  useTermsOfService();
  useUnlicensedUserRouting();
  useRequireLogin();

  if (isLoading) {
    return <CabSpinner scale={4.5} color={"inherit"} sx={{marginTop: '7em'}}/>;
  } else if (!isLoading && !isAuthenticated) {
    return <></>;
  } else {
    return <Outlet />;
  }

};

const AppUrlListenerContainer: FC = () => {

  const navigate = useNavigate();

  useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: joincabinet://joincabinet.com/login?code=xxx
      // slug = /login?code=xxx
      try {
        // On iOS the browser doesn't close when the deeplink happens 
        Browser.close();
      } catch (e) {
        // If the browser isn't open this might throw an error 
      }
      const slug = event.url.split('localhost').pop();

      if (slug && slug.includes("code")) {
        navigate(slug);
        // Need to reload the page to get OAuth to trigger using the code param
        window.location.reload();
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const AppUrlListener = AppUrlListenerContainer;

